














import { defineComponent, ref, reactive } from '@vue/composition-api';
import { useAuth } from '@/admin/auth';
import { useNotification } from '@/composition/notification';

export default defineComponent({
  name: 'PasswordReminder',
  setup() {
    const { remindPassword } = useAuth();
    const notification = useNotification();
    const formData = reactive({
      email: '',
    });
    const isSending = ref(false);

    const send = async () => {
      isSending.value = true;
      try {
        await remindPassword(formData.email);
        notification.notify('送信しました')
      } catch (error) {
        notification.error(error);
      }
      isSending.value = false;
    }

    return { formData, isSending, send };
  },
});
